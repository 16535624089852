@keyframes ldio-hpigofqkvpq {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
}

#custom-zIndex {
    z-index: 1200 !important;
}

.custom-price-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-name,
.custom-price,
.custom-plus,
.custom-plus-price{
    margin: 0;
    line-height: 35px;
    width: auto;
    font-family: Roboto-Condensed, sans-serif;
    font-size: 21px;
}

@media screen and (max-width: 600px){
  .custom-name,
  .custom-price,
  .custom-plus,
  .custom-plus-price{
    font-size: 18px;
  }
  .adaptive-for-map {
    height: 400px !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 500px){
  .custom-name,
  .custom-price,
  .custom-plus,
  .custom-plus-price{
    font-size: 16px;
  }
}
@media screen and (max-width: 440px){
  .custom-name,
  .custom-price,
  .custom-plus,
  .custom-plus-price{
    font-size: 11px;
  }
}

.custom-plus::before {
    content: '';
    margin-right: 20px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #99989875;
    z-index: 100000;
}

.ldio-hpigofqkvpq div {
    position: absolute;
    animation: ldio-hpigofqkvpq 1.31s linear infinite;
    width: 101.49999999999999px;
    height: 101.49999999999999px;
    top: 50.74999999999999px;
    left: 50.74999999999999px;
    border-radius: 50%;
    box-shadow: 0 2.233px 0 0 #fe0000;
    transform-origin: 50.74999999999999px 51.866499999999995px;
}
.loadingio-spinner-eclipse-k5otvilyroo {
    width: 203px;
    height: 203px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-hpigofqkvpq {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-hpigofqkvpq div {
    box-sizing: content-box;
}

.philosophy {
  width: 75%;
}

@media screen and (max-width: 900px){
  .philosophy {
    width: 90%;
    text-align: center;
  }
}

